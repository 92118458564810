exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-art-tsx": () => import("./../../../src/pages/art.tsx" /* webpackChunkName: "component---src-pages-art-tsx" */),
  "component---src-pages-design-tsx": () => import("./../../../src/pages/design.tsx" /* webpackChunkName: "component---src-pages-design-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-client-list-page-tsx": () => import("./../../../src/templates/ClientListPage.tsx" /* webpackChunkName: "component---src-templates-client-list-page-tsx" */),
  "component---src-templates-client-page-tsx": () => import("./../../../src/templates/ClientPage.tsx" /* webpackChunkName: "component---src-templates-client-page-tsx" */),
  "component---src-templates-default-page-tsx": () => import("./../../../src/templates/DefaultPage.tsx" /* webpackChunkName: "component---src-templates-default-page-tsx" */),
  "component---src-templates-domain-page-tsx": () => import("./../../../src/templates/DomainPage.tsx" /* webpackChunkName: "component---src-templates-domain-page-tsx" */),
  "component---src-templates-project-page-tsx": () => import("./../../../src/templates/ProjectPage.tsx" /* webpackChunkName: "component---src-templates-project-page-tsx" */)
}

